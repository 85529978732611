import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { match } from 'ts-pattern'
import { z } from 'zod'
import codemods from '../codemods.json' assert { type: 'json' }
import { ApiError, client } from './user-platform-api'
import {
  CodemodCatalog,
  CodemodHitStatusesByRepositoriesId,
  CodemodHitStatusesByRepositoriesIdLenient,
  CodemodPreference,
  CodemodWithDescription,
  Codemods,
  CreatePatchRequest,
  CreateScanRequestFormData,
  CreateScanResponse,
  Credentials,
  DeprecatedChangesets,
  DeprecatedChangesetsDeprecated,
  GitHubRepositoryResponse,
  HALPaginatedAnalyzedFindings,
  HALPaginatedGeneric,
  Installations,
  OwnerPreference,
  OwnerPreferences,
  PaginatedAnalysisResponse,
  PaginatedChangesets,
  PaginatedFixes,
  PaginatedResponseRepositoryResponse,
  PatchResponse,
  PullRequests,
  RepositoriesWithInstallationId,
  RepositoryResponse,
  ScanAnalysis,
  User,
} from './user-platform-api-schemas'
import { components, paths } from './user-platform-api.d'

type ShapesMatch<T, U> = [T] extends [U] ? ([U] extends [T] ? true : false) : false
type TypesMatch<T, U> =
  ShapesMatch<T, U> extends true ? (ShapesMatch<keyof T, keyof U> extends true ? true : false) : false
type Expect<T extends true> = T
type Response<T> = T extends { get: { responses: { 200: { content: { 'application/json': infer R } } } } } ? R : never

export type TestUser = Expect<TypesMatch<User, Response<paths['/api/user']>>>
export const useGetUser = ({ enabled = true }: { enabled?: boolean } = {}) =>
  useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      const { data } = await client.GET('/api/user')
      return User.parse(data)
    },
    staleTime: 300_000, // 5 min
    enabled,
  })

export type TestInstallations = Expect<TypesMatch<Installations, Response<paths['/api/user/installations']>>>
export const useGetInstallations = () => {
  let { data, ...rest } = useQuery({
    queryKey: ['installations'],
    queryFn: async () => {
      const { data } = await client.GET('/api/user/installations')
      return Installations.parse(data)
    },
    staleTime: 120_000, // 2 min
  })
  data = useMemo(() => data ?? [], [data])
  return { data, ...rest }
}

export type TestRepositories = Expect<
  TypesMatch<RepositoriesWithInstallationId, Response<paths['/api/user/repositories']>>
>
export const useGetRepositories = (installationIds: number[]) => {
  let { data, ...rest } = useQuery({
    queryKey: ['repositories'],
    queryFn: async () => {
      const { data } = await client.GET('/api/user/repositories', {
        params: { query: { installation_id: installationIds } },
      })

      return RepositoriesWithInstallationId.parse(data)
    },
    staleTime: 120_000, // 2 min
    enabled: installationIds.length > 0,
  })

  data = data ?? []
  return { data, ...rest }
}

export type TestRepositoriesV1 = Expect<
  // @ts-expect-error: TS expect error because generated schema does not account for both types of repository objects
  TypesMatch<PaginatedResponseRepositoryResponse, Response<paths['/api/v1/repositories']>>
>

// @ts-expect-error: TS expect error because API does not include type
export type name1 = Expect<TypesMatch<RepositoryResponse, components['schemas']['RepositoryResponse']>>
// @ts-expect-error: TS expect error because API is not generated with the correct case, nor does it include the type
export type name2 = Expect<TypesMatch<GitHubRepositoryResponse, components['schemas']['GitHubRepositoryResponse']>>
export type name3 = Expect<
  TypesMatch<PaginatedResponseRepositoryResponse['items'][number], GitHubRepositoryResponse | RepositoryResponse>
>
export const useGetRepositoriesV1 = ({ pageNumber, pageSize }: { pageNumber: number; pageSize: number }) => {
  let { data, ...rest } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: ['repositoriesV1', pageNumber, pageSize],
    queryFn: async () => {
      const { data } = await client.GET('/api/v1/repositories', {
        params: { query: { page_number: pageNumber, page_size: pageSize } },
      })

      return PaginatedResponseRepositoryResponse.parse(data)
    },
  })

  data = useMemo(() => data ?? { total: 0, page: { number: 0, size: 0 }, items: [] }, [data])
  return { data, ...rest }
}

export type TestPullRequest = Expect<TypesMatch<PullRequests, Response<paths['/api/user/pull-requests']>>>
export const useGetPixeebotPullRequests = (installationOwnerLogins: string[]) => {
  let { data, ...rest } = useQuery({
    queryKey: ['pull-requests', installationOwnerLogins],
    queryFn: async () => {
      const { data } = await client.GET('/api/user/pull-requests', {
        params: { query: { account_logins: installationOwnerLogins } },
      })

      return PullRequests.parse(data)
    },
    enabled: installationOwnerLogins.length > 0,
  })

  data = useMemo(() => data ?? [], [data])
  return { data, ...rest }
}

export type TestCodemodHitStatusesByRepositoriesId = Expect<
  TypesMatch<CodemodHitStatusesByRepositoriesId, Response<paths['/api/user/repositories/codemods']>>
>
export const useGetRepositoriesCodemodHitStatuses = (installationIds: number[]) => {
  let { data, ...rest } = useQuery({
    queryKey: ['codemod-hit-status'],
    queryFn: async () => {
      const { data } = await client.GET('/api/user/repositories/codemods', {
        params: { query: { installation_id: installationIds } },
      })

      return CodemodHitStatusesByRepositoriesIdLenient.parse(data)
    },
    enabled: installationIds.length > 0,
  })

  data = data ?? []
  return { data, ...rest }
}

export type TestOwnerPreferences = Expect<TypesMatch<OwnerPreferences, Response<paths['/api/owners/preferences']>>>
export const useGetOwnerPreferences = (ownerIds: number[]) => {
  let { data, ...rest } = useQuery({
    queryKey: ['owner-preferences', ownerIds],
    queryFn: async () => {
      const { data } = await client.GET('/api/owners/preferences', { params: { query: { owner_id: ownerIds } } })

      return OwnerPreferences.parse(data).map(ownerPreference => ({
        ownerId: ownerPreference.owner_id,
        codemodPreference: (({ codemod_catalog }: OwnerPreference): CodemodPreference => {
          return match(codemod_catalog)
            .with('MORE', () => 'everything')
            .with('MODERATE', () => 'security+tools')
            .with('DEFAULT', () => 'security+tools')
            .with('LESS', () => 'tools-only')
            .with('CUSTOM', () => 'custom')
            .exhaustive() as CodemodPreference
        })(ownerPreference),
      }))
    },
    enabled: ownerIds.length > 0,
  })

  data = data ?? []
  return { data, ...rest }
}

export const useGetCodemodByIds = () => {
  return useQuery({
    queryKey: ['codemods'],
    queryFn: async () => {
      return Codemods.parse(codemods)
    },
  })
}

export const useGetCodemodById = ({ codemodId }: { codemodId?: string }) => {
  return useQuery({
    queryKey: ['codemod', codemodId],
    queryFn: async () => {
      if (!codemodId) throw new Error('Codemod ID is required')
      const codemod = codemods[codemodId]
      if (!codemod) throw new Error(`Codemod with id ${codemodId} not found`)
      return CodemodWithDescription.parse(codemod)
    },
    enabled: !!codemodId,
  })
}

export type TestDeprecatedChangesetsByAnalysisIdAndFindingId = Expect<
  TypesMatch<DeprecatedChangesets, Response<paths['/api/analysis/{analysis_id}/scan/{scan_id}/changesets']>>
>
export const useGetDeprecatedChangesetsByAnalysisIdAndFindingId = ({
  analysisId,
  scanId,
  issueId,
  findingId,
}: {
  analysisId: string
  scanId: string
  issueId?: string
  findingId?: string
}) => {
  return useQuery({
    queryKey: ['useGetDeprecatedChangesetsByAnalysisIdAndFindingId', analysisId, scanId, issueId, findingId],
    queryFn: async () => {
      const { data } = await client.GET('/api/analysis/{analysis_id}/scan/{scan_id}/changesets', {
        params: {
          path: {
            analysis_id: analysisId,
            scan_id: scanId,
          },
          query: {
            issue: issueId,
            finding_id: findingId,
          },
        },
      })

      return DeprecatedChangesets.parse(data)
    },
  })
}

export type TestDeprecatedChangesetsDeprecated = Expect<
  TypesMatch<
    DeprecatedChangesetsDeprecated,
    Response<paths['/api/changesets/{installation_id}/{repository_id}/{analysis_id}/{codemod_id}']>
  >
>
export const useGetDeprecatedChangesetsByCodemodIdDeprecated = ({
  installationId,
  repositoryId,
  analysisId,
  codemodId,
}: {
  installationId: string
  repositoryId: string
  analysisId: string
  codemodId: string
}) => {
  let { data, ...rest } = useQuery<DeprecatedChangesetsDeprecated, Error | ApiError>({
    queryKey: ['useGetDeprecatedChangesetsByCodemodIdDeprecated', installationId, repositoryId, analysisId, codemodId],
    queryFn: async () => {
      const { response, data } = await client.GET(
        '/api/changesets/{installation_id}/{repository_id}/{analysis_id}/{codemod_id}',
        {
          params: {
            path: {
              installation_id: Number(installationId),
              repository_id: Number(repositoryId),
              analysis_id: analysisId,
              codemod_id: codemodId,
            },
          },
        }
      )

      if (response.status === 403 || response.status !== 200) {
        throw new ApiError(response.status)
      }

      return DeprecatedChangesetsDeprecated.parse(data)
    },
  })
  data = data ?? []
  return { data, ...rest }
}

export type TestDeprecatedChangesets = Expect<
  TypesMatch<DeprecatedChangesets, Response<paths['/api/analysis/{analysis_id}/changesets']>>
>
export const useGetDeprecatedChangesetsByCodemodId = ({
  analysisId,
  codemodId,
}: {
  analysisId: string
  codemodId: string
}) => {
  let { data, ...rest } = useQuery({
    queryKey: ['useGetDeprecatedChangesetsByCodemodId', analysisId, codemodId],
    queryFn: async () => {
      const { response, data, error } = await client.GET('/api/analysis/{analysis_id}/changesets', {
        params: {
          path: {
            analysis_id: analysisId,
          },
          query: {
            codemod_id: codemodId,
          },
        },
      })

      if (response.status === 403 || response.status !== 200) {
        throw new ApiError(response.status, error)
      }

      return DeprecatedChangesets.parse(data)
    },
  })
  data = data ?? []
  return { data, ...rest }
}

export const useGetScanAnalysisV1 = ({ analysisId, enabled = true }: { analysisId: string; enabled?: boolean }) => {
  return useQuery({
    queryKey: ['scan-analysis-v1', 'invalidate cache jan 18 2025', analysisId],
    queryFn: async () => {
      const response = await fetch(`/api/v1/analyses/${analysisId}`)

      if (!response.ok) {
        throw new ApiError(response.status)
      }

      const data = await response.json()
      return ScanAnalysis.parse(data)
    },
    enabled,
  })
}

export const useGetFindingArticle = ({
  analysisId,
  findingId,
  enabled,
}: {
  analysisId: string
  findingId: string
  enabled: boolean
}) => {
  return useQuery<string, ApiError>({
    queryKey: ['findingArticle', analysisId, findingId],
    queryFn: async () => {
      const response = await fetch(`/api/v1/analyses/${analysisId}/findings/${findingId}/article`)

      if (!response.ok) {
        let errorText: string | undefined
        try {
          errorText = await response.text()
        } catch (e) {}

        throw new ApiError(response.status, errorText)
      }

      const data = await response.text()
      return z.string().parse(data)
    },
    enabled,
    retry: 1,
  })
}

export const usePostPullRequestByFindingId = ({
  analysisId,
  scanId,
  findingId,
  onSuccess,
  onError,
}: {
  analysisId: string
  scanId: string
  findingId: string
  onSuccess?: any
  onError?: any
}) =>
  useMutation({
    mutationFn: async () => {
      const { response } = await client.POST('/api/analysis/{analysis_id}/scan/{scan_id}/pull-request', {
        params: { path: { analysis_id: analysisId, scan_id: scanId } },
        body: {
          finding_id: findingId,
          scan_id: null,
        },
        parseAs: 'stream',
      })
      if (response.status !== 201) {
        throw new ApiError(response.status)
      }
    },
    onSuccess,
    onError,
  })

export const usePutOwnerPreferenceMutation = ({ onSuccess, onError }: { onSuccess?: any; onError?: any } = {}) => {
  return useMutation({
    mutationFn: ({ ownerId, codemodPreference }: { ownerId: number; codemodPreference: CodemodPreference }) => {
      return client.PUT('/api/owners/{owner_id}/preferences', {
        params: { path: { owner_id: ownerId } },
        body: {
          tier: 'DEFAULT',
          codemod_catalog: ((codemodPreference: CodemodPreference) => {
            return match(codemodPreference)
              .with('everything', () => 'MORE')
              .with('security+tools', () => 'MODERATE')
              .with('tools-only', () => 'LESS')
              .with('custom', () => 'CUSTOM')
              .exhaustive() as CodemodCatalog
          })(codemodPreference),
        },
      })
    },
    onSuccess,
    onError,
  })
}

export const usePostAnalysisMutation = ({ onSuccess, onError }: { onSuccess?: any; onError?: any } = {}) => {
  return useMutation({
    mutationFn: async ({
      installationId,
      repositoryId,
      codemodId,
    }: {
      installationId: number
      repositoryId: number
      codemodId: string
    }) => {
      const { response, data, error } = await client.POST('/api/user/{installation_id}/{repository_id}/analyses', {
        params: {
          path: {
            installation_id: installationId,
            repository_id: repositoryId,
          },
        },
        body: {
          codemod_id: codemodId,
        },
      })

      if (response.status === 400 || response.status === 403 || response.status === 409) {
        throw new Error(error?.message || `API request failed with status ${response.status}`)
      }

      if (response.status !== 200) {
        throw new Error(`API request failed with status ${response.status}`)
      }

      return z.object({ analysis_id: z.string() }).parse(data).analysis_id
    },
    onSuccess,
    onError,
  })
}

export const usePostUploadScan = ({
  onSuccess,
  onError,
  retry,
}: { onSuccess?: any; onError?: any; retry?: number } = {}) => {
  return useMutation({
    mutationFn: async ({ repositoryId, body }: { repositoryId: string; body: CreateScanRequestFormData }) => {
      const { response, data, error } = await client.POST(`/api/v1/repositories/{repository_id}/scans`, {
        params: { path: { repository_id: repositoryId } },
        // @ts-expect-error: open api annotations need to be updated
        body,
        bodySerializer: (body): FormData => {
          if (!body || !body.metadata || !body.files) {
            throw new Error('Metadata and files are required')
          }
          const formData = new FormData()
          formData.append(
            'metadata',
            new Blob(
              [JSON.stringify({ ...body.metadata, sha: body.metadata.sha === '' ? undefined : body.metadata.sha })],
              { type: 'application/json' }
            )
          )
          Object.entries(body.files).forEach(([_, file]) => {
            formData.append('files', file)
          })
          return formData
        },
      })
      if (response.status !== 201) {
        throw new ApiError(response.status, error)
      }
      return CreateScanResponse.parse({ scan_id: data?.uuid })
    },
    onSuccess,
    onError,
    retry,
  })
}

export const usePostAnalyzeScan = ({
  onSuccess,
  onError,
  retry,
}: { onSuccess?: any; onError?: any; retry?: number } = {}) => {
  return useMutation({
    mutationFn: async ({ scanId }: { scanId: string }) => {
      const { response, data, error } = await client.POST('/api/v1/scans/{scan_id}/analyses', {
        params: { path: { scan_id: scanId } },
      })
      if (response.status !== 201) {
        throw new ApiError(response.status, error)
      }
      const analysisSchema = z.object({
        id: z.string(),
      })
      const parsedData = analysisSchema.parse(data)
      return { analysis_id: parsedData.id }
    },
    onSuccess,
    onError,
    retry,
  })
}

export const usePostAddRepository = ({ onSuccess, onError }: { onSuccess?: any; onError?: any } = {}) => {
  return useMutation({
    mutationFn: async ({ name, credentials, url }: { name: string; credentials: Credentials; url: string }) => {
      const { response, error } = await client.POST('/api/v1/repositories', {
        body: {
          type: 'git',
          name,
          credentials,
          url,
        },
      })
      if (response.status !== 201) {
        throw new ApiError(response.status, error)
      }
    },
    onSuccess,
    onError,
  })
}

export const useGetFindings = ({
  analysisId,
  pageNumber,
  pageSize,
}: {
  analysisId: string
  pageNumber: number
  pageSize: number
}) => {
  return useQuery({
    placeholderData: keepPreviousData,
    queryKey: ['hal-findings', analysisId, pageNumber, pageSize],
    queryFn: async () => {
      const response = await fetch(
        `/api/v1/analyses/${analysisId}/findings?page_number=${pageNumber}&page_size=${pageSize}`,
        {
          headers: {
            Accept: 'application/hal+json',
          },
        }
      )

      if (!response.ok) {
        throw new ApiError(response.status)
      }

      const data = await response.json()
      return HALPaginatedAnalyzedFindings.parse(data)
    },
    staleTime: 0,
    gcTime: 0,
  })
}

export const useGetFixesForFinding = ({
  analysisId,
  findingId,
  pageNumber = 0,
  pageSize = 10,
  enabled,
}: {
  analysisId: string
  findingId: string
  pageNumber?: number
  pageSize?: number
  enabled: boolean
}) => {
  return useQuery({
    queryKey: ['fixesForFinding', analysisId, findingId, pageNumber, pageSize],
    queryFn: async () => {
      const response = await fetch(
        `/api/v1/analyses/${analysisId}/findings/${findingId}/fixes?page_number=${pageNumber}&page_size=${pageSize}`,
        {
          headers: {
            Accept: 'application/hal+json',
          },
        }
      )

      const data = await response.json()
      return PaginatedFixes.parse(data)
    },
    enabled,
  })
}

export const useGetChangesetsForFix = ({
  fixId,
  pageNumber = 0,
  pageSize = 10,
  enabled,
}: {
  fixId: string
  pageNumber?: number
  pageSize?: number
  enabled: boolean
}) => {
  return useQuery({
    queryKey: ['changesetsForFix', fixId, pageNumber, pageSize],
    queryFn: async () => {
      const response = await fetch(
        `/api/v1/fixes/${fixId}/changesets?page_number=${pageNumber}&page_size=${pageSize}`,
        {
          headers: {
            Accept: 'application/hal+json',
          },
        }
      )

      const data = await response.json()
      return PaginatedChangesets.parse(data)
    },
    enabled,
  })
}

export const useGetAnalyses = ({
  repositoryId,
  pageNumber = 0,
  pageSize = 10,
}: {
  repositoryId: string
  pageNumber?: number
  pageSize?: number
}) => {
  return useQuery({
    queryKey: ['analyses', repositoryId, pageNumber, pageSize],
    queryFn: async () => {
      const { response, data } = await client.GET('/api/v1/repositories/{repository_id}/analyses', {
        params: {
          path: { repository_id: repositoryId },
          query: {
            page_number: pageNumber,
            page_size: pageSize,
          },
        },
      })

      if (response.status === 404) {
        throw new Error('Repository not found')
      }

      if (response.status !== 200) {
        throw new Error(`API request failed with status ${response.status}`)
      }

      return PaginatedAnalysisResponse.parse(data)
    },
  })
}

export const usePostPatch = ({
  onSuccess,
  onError,
  retry,
}: { onSuccess?: any; onError?: any; retry?: number } = {}) => {
  return useMutation({
    mutationFn: async ({
      analysisId,
      createPatchRequest,
    }: {
      analysisId: string
      createPatchRequest: CreatePatchRequest
    }) => {
      const parsedCreatePatchRequest = CreatePatchRequest.parse(createPatchRequest)

      const response = await fetch(`/api/v1/analyses/${analysisId}/patches`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(parsedCreatePatchRequest),
      })

      if (response.status !== 201) {
        let errorText: string | undefined
        try {
          errorText = await response.text()
        } catch (e) {}

        throw new ApiError(response.status, errorText)
      }

      const location = response.headers.get('Location')
      if (!location) {
        throw new Error('No Location header in response')
      }

      return { location }
    },
    onSuccess,
    onError,
    retry,
  })
}

export const useGetPatch = ({ analysisId, patchId }: { analysisId: string; patchId: string }) => {
  return useQuery({
    queryKey: ['patch', analysisId, patchId],
    queryFn: async () => {
      const response = await fetch(`/api/v1/analyses/${analysisId}/patches/${patchId}`)

      if (!response.ok) {
        let errorText: string | undefined
        try {
          errorText = await response.text()
        } catch (e) {}

        throw new ApiError(response.status, errorText)
      }

      const data = await response.json()
      return PatchResponse.parse(data)
    },
  })
}

export const useListPatches = ({
  analysisId,
  pageNumber,
  pageSize,
}: {
  analysisId: string
  pageNumber?: number
  pageSize?: number
}) => {
  return useQuery({
    queryKey: ['patches', analysisId, pageNumber, pageSize],
    queryFn: async () => {
      const response = await fetch(
        `/api/v1/analyses/${analysisId}/patches?page_number=${pageNumber}&page_size=${pageSize}`
      )

      if (!response.ok) {
        let errorText: string | undefined
        try {
          errorText = await response.text()
        } catch (e) {}

        throw new ApiError(response.status, errorText)
      }

      const data = await response.json()
      return HALPaginatedGeneric(PatchResponse).parse(data)
    },
  })
}
