import { GitMergeIcon, MarkGithubIcon } from '@primer/octicons-react'
import * as Dialog from '@radix-ui/react-dialog'
import { useQueryClient } from '@tanstack/react-query'
import {
  ColumnDef,
  RowSelectionState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import classNames from 'classnames'
import fuzzysort from 'fuzzysort'
import { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { match } from 'ts-pattern'
import { DefaultButton } from '../components/default-button.tsx'
import { UploadScanFormDrawer } from '../components/drawer/upload-scan-form-drawer.tsx'
import { FixesStatusBadge } from '../components/fixes-status-badge.tsx'
import { GhostButton } from '../components/ghost-button.tsx'
import { PaginationControls } from '../components/pagination-controls.tsx'
import AzureLightIcon from '../components/svg/azure-light.svg?react'
import { TableBodySkeleton } from '../components/table-body-skeleton.tsx'
import { Pagination } from '../components/table.tsx'
import { TextInput } from '../components/text-input.tsx'
import { Routes } from '../routes.ts'
import {
  useGetAnalyses,
  useGetInstallations,
  useGetPixeebotPullRequests,
  useGetRepositoriesV1,
  usePostAddRepository,
} from '../utils/api-client/user-platform-api-hooks.ts'
import {
  AnyRepositoryResponse,
  PaginatedResponseRepositoryResponse,
  PullRequest,
} from '../utils/api-client/user-platform-api-schemas.ts'
import { useAddToast } from '../utils/higher-order-components/with-toasts.tsx'
import { useEnvironmentData } from '../utils/hooks/use-environment-data.ts'
import * as styles from './repositories-page.css.ts'

export function RepositoriesPage() {
  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: 10,
  })

  const { data: repositories, isPending: repositoriesIsPending } = useGetRepositoriesV1({
    pageNumber: pagination.pageIndex,
    pageSize: pagination.pageSize,
  })
  return (
    <main className={styles.mainContainer}>
      <RepositoriesTable
        repositories={repositories}
        repositoriesIsPending={repositoriesIsPending}
        pagination={pagination}
        setPagination={setPagination}
      />
    </main>
  )
}

export type RepositoryWithData = {
  pixeebotPRs: PullRequest[]
} & AnyRepositoryResponse

type RepositoriesTableProps = {
  repositories: PaginatedResponseRepositoryResponse
  repositoriesIsPending: boolean
  pagination: Pagination
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>
}

export function RepositoriesTable({
  repositories,
  repositoriesIsPending,
  pagination,
  setPagination,
}: RepositoriesTableProps) {
  const environmentData = useEnvironmentData()

  const { data: installations } = useGetInstallations()
  const { data: allPixeebotPRs } = useGetPixeebotPullRequests(
    installations.map(installation => installation.account.login)
  )

  const toRepositoriesWithData = useCallback(createToRepositoriesWithData(allPixeebotPRs), [allPixeebotPRs])
  const repositoriesWithData = useMemo(
    () => repositories.items.map(toRepositoriesWithData),
    [repositories, allPixeebotPRs]
  )

  const [selectedRepositoryId, setSelectedRepositoryId] = useState<string | null>(null)
  const selectedRepositoryDisplayName = repositoriesWithData.find(
    repository => repository.id === selectedRepositoryId
  )?.name

  const columns = useMemo<ColumnDef<RepositoryWithData>[]>(
    () => createColumnsDefinitions(setSelectedRepositoryId),
    [setSelectedRepositoryId]
  )
  const [globalFilter, setGlobalFilter] = useState('')

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const table = useReactTable({
    getRowId: data => String(data.id),
    data: repositoriesWithData,
    columns,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      globalFilter,
      rowSelection,
    },
    globalFilterFn: (row, columnId, value) => {
      const results = fuzzysort.go(value, [row.getValue(columnId)])
      return results.length > 0
    },
    filterFns: {
      select: (row, columnId, value) => {
        const selectedValues = value
        const columnValue: any = row.getValue(columnId)

        return selectedValues.some(value => value === columnValue)
      },
    },
  })

  return (
    <div className={styles.topLevelContainer}>
      {selectedRepositoryId && selectedRepositoryDisplayName && (
        <UploadScanFormDrawer
          repositoryId={selectedRepositoryId}
          repositoryDisplayName={selectedRepositoryDisplayName}
          handleCloseDrawer={() => setSelectedRepositoryId(null)}
        />
      )}
      <div className={styles.tableControls}>
        <div>
          <h4 className={styles.tableHeading}>
            Repositories <span className={styles.installationsCount}>({repositories.total})</span>
          </h4>
          <p className={styles.tableDescription}>
            Locations where {environmentData.githubAppName} has been configured for analysis
          </p>
        </div>

        <div className={styles.tableControlsRight}>
          <TextInput value={globalFilter ?? ''} onChange={value => setGlobalFilter(String(value))} size="small" />

          <AddRepositoryModal />
        </div>
      </div>
      <table className={styles.table}>
        <thead className={styles.tableHead}>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th
                    key={header.id}
                    className={classNames(styles.columnHeader, styles.cell, styles.installationsTableCell)}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        {repositoriesIsPending ? (
          <TableBodySkeleton rowCount={7} columnCount={columns.length} rowHeightInPixels={43} />
        ) : (
          <tbody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <tr key={row.id} className={styles.row}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} className={classNames(styles.cell, styles.installationsTableCell)}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td className={classNames(styles.cell, styles.emptyStateCell)} colSpan={columns.length}>
                  <div className={styles.emptyStateText}>
                    No repositories found. Please connect your first repository.
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
      <div className={styles.tableFooter}>
        <PaginationControls
          tableLabel="Installations"
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          rowCount={repositories.total}
          onNextPage={() => {
            setPagination(prevState => ({
              pageIndex: prevState.pageIndex + 1,
              pageSize: prevState.pageSize,
            }))
          }}
          onPreviousPage={() =>
            setPagination(prevState => ({
              pageIndex: prevState.pageIndex - 1,
              pageSize: prevState.pageSize,
            }))
          }
          onChangePageSize={value => setPagination({ pageIndex: 0, pageSize: value })}
        />
      </div>
    </div>
  )
}

const createToRepositoriesWithData =
  (allPixeebotPRs: PullRequest[]) =>
  (repository: AnyRepositoryResponse): RepositoryWithData => {
    const repositoryPixeebotPRs = allPixeebotPRs.filter(
      pullRequest => pullRequest.repository_url === `https://api.github.com/repos/${repository.name}`
    )

    return {
      ...repository,
      pixeebotPRs: repositoryPixeebotPRs,
    }
  }

function RepoLocation({ repositoryWithData }: { repositoryWithData: RepositoryWithData }) {
  //  const activeVariant = isActive ? 'active' : 'inactive'
  const SCMIcon = match(repositoryWithData.type)
    .with('azure', () => {
      return <AzureLightIcon className={styles.scmIcon} />
    })
    .with('github', () => {
      return <MarkGithubIcon className={styles.scmIcon} />
    })
    .with('git', () => {
      return <GitMergeIcon aria-label="Git icon" className={styles.scmIcon} />
    })
    .otherwise(() => {
      return <GitMergeIcon aria-label="Git icon" className={styles.scmIcon} />
    })

  return (
    <span className={styles.repositoryName}>
      {SCMIcon}
      {repositoryWithData.name}
    </span>
  )
}

const createColumnsDefinitions = (
  setSelectedRepositoryId: React.Dispatch<React.SetStateAction<string | null>>
): ColumnDef<RepositoryWithData>[] => {
  const columnHelper = createColumnHelper<RepositoryWithData>()

  return [
    columnHelper.accessor((row: RepositoryWithData): string => row.name, {
      id: 'repository',
      cell: ({ row }) => {
        return <RepoLocation repositoryWithData={row.original} />
      },
      header: () => <span>REPOSITORY</span>,
    }) as ColumnDef<RepositoryWithData>,
    columnHelper.display({
      id: 'fixes',
      cell: ({ row }) => {
        const openPRs = row.original.pixeebotPRs.filter(pr => pr.closed_at === null)
        return openPRs.length > 0 ? (
          <div className={styles.center}>
            <FixesStatusBadge
              suggestionsCount={openPRs.length}
              link={`https://github.com/${row.original.name}/pulls`}
            />
          </div>
        ) : (
          <></>
        )
      },
      header: ({}) => <span className={styles.center}>OPEN FIXES</span>,
    }),
    columnHelper.display({
      id: 'analysis',
      cell: ({ row }) => {
        const { data: analyses } = useGetAnalyses({
          repositoryId: row.original.id,
          pageSize: 1,
          pageNumber: 0,
        })
        const latestAnalysis = analyses?.items?.[0]

        return (
          <div className={styles.center}>
            {latestAnalysis ? (
              <>
                <Link to={Routes.AnalysisDetailsPage.createPath(latestAnalysis.id)} className={styles.analysisLink}>
                  Latest Analysis
                </Link>
                &nbsp;
                <GhostButton size="small" onClick={() => setSelectedRepositoryId(row.original.id)} type="secondary">
                  <svg fill="currentColor" height="16px" width="16px" viewBox="0 0 374.116 374.116" aria-hidden="true">
                    <g>
                      <path d="M344.058,207.506c-16.568,0-30,13.432-30,30v76.609h-254v-76.609c0-16.568-13.432-30-30-30c-16.568,0-30,13.432-30,30v106.609c0,16.568,13.432,30,30,30h314c16.568,0,30-13.432,30-30V237.506C374.058,220.938,360.626,207.506,344.058,207.506z" />
                      <path d="M123.57,135.915l33.488-33.488v111.775c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30V102.426l33.488,33.488c5.857,5.858,13.535,8.787,21.213,8.787c7.678,0,15.355-2.929,21.213-8.787c11.716-11.716,11.716-30.71,0-42.426L208.271,8.788c-11.715-11.717-30.711-11.717-42.426,0L81.144,93.489c-11.716,11.716-11.716,30.71,0,42.426C92.859,147.631,111.855,147.631,123.57,135.915z" />
                    </g>
                  </svg>
                </GhostButton>
              </>
            ) : (
              <DefaultButton size="small" onClick={() => setSelectedRepositoryId(row.original.id)}>
                Upload scan results
              </DefaultButton>
            )}
          </div>
        )
      },
      header: ({}) => (
        <>
          <span className={styles.center}>ANALYSIS</span>
        </>
      ),
    }),
  ]
}

const AddRepositoryModal: React.FC<{}> = () => {
  const { handleAddToastWithTimeout } = useAddToast()
  const [selectedConnectionType, setSelectedConnectionType] = useState('pixeebotApp')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [url, setUrl] = useState('')
  const [addRepositoryDialogOpen, setAddRepositoryDialogOpen] = useState(false)
  const queryClient = useQueryClient()
  const addRepositoryMutation = usePostAddRepository({
    onSuccess: () => {
      handleAddToastWithTimeout({ message: <>Repository was successfully connected.</>, variant: 'success' })
      queryClient.invalidateQueries({ queryKey: ['repositoriesV1'] })
      setAddRepositoryDialogOpen(false)
    },
    onError: (error: any) => {
      console.error(error)
      handleAddToastWithTimeout({
        message: <>Repository connection failed with error: {error.message}.</>,
        variant: 'error',
      })
      if (error.bodyAsText && typeof error.bodyAsText === 'string') {
        handleAddToastWithTimeout({
          message: <pre style={{ margin: '0px' }}>{error.bodyAsText}</pre>,
          variant: 'error',
        })
      }
    },
  })
  const extractRepoName = (url: string): string => {
    const match = url.match(/\/([^\/]+)\.git$/)
    return match ? (match[1] ?? url) : url
  }

  const SelectedRadioSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect width="16" height="16" rx="8" fill="#C10B5E" />
      <circle cx="8" cy="8" r="3" fill="white" />
    </svg>
  )

  const UnselectedRadioSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#AEABB6" />
    </svg>
  )

  const handleConnectClick = useCallback(() => {
    const name = extractRepoName(url)
    addRepositoryMutation.mutate(
      {
        name,
        credentials: {
          username,
          password,
        },
        url,
      },
      {
        onSuccess: () => {
          setUrl('')
          setUsername('')
          setPassword('')
        },
      }
    )
  }, [url, username, password, addRepositoryMutation])

  return (
    <Dialog.Root open={addRepositoryDialogOpen} onOpenChange={setAddRepositoryDialogOpen}>
      <Dialog.Trigger asChild>
        <DefaultButton size="small" buttonType="secondary">
          + &nbsp; Add repository
        </DefaultButton>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.dialogOverlay} />
        <Dialog.Content className={styles.dialogContent} aria-describedby={undefined}>
          <Dialog.Title className={styles.title}>Add repository</Dialog.Title>
          <form>
            <div className={styles.radioGroup}>
              <label className={styles.radioButton}>
                <input
                  type="radio"
                  name="connectionType"
                  value="pixeebotApp"
                  checked={selectedConnectionType === 'pixeebotApp'}
                  onChange={() => setSelectedConnectionType('pixeebotApp')}
                  className={styles.hiddenRadioButton}
                />
                {selectedConnectionType === 'pixeebotApp' ? <SelectedRadioSVG /> : <UnselectedRadioSVG />}
                Install the Pixeebot GitHub application (GitHub repositories only)
              </label>
              <label className={styles.radioButton}>
                <input
                  type="radio"
                  name="connectionType"
                  value="gitClient"
                  checked={selectedConnectionType === 'gitClient'}
                  onChange={() => setSelectedConnectionType('gitClient')}
                  className={styles.hiddenRadioButton}
                />
                {selectedConnectionType === 'gitClient' ? <SelectedRadioSVG /> : <UnselectedRadioSVG />}
                Connect via Git client (clone a repository from any SCM)
              </label>
            </div>
            {selectedConnectionType !== 'pixeebotApp' && (
              <div className={styles.inputGroup}>
                <label className={styles.inputLabel}>
                  URL
                  <span className={styles.requiredAsterisk} title="required">
                    *
                  </span>
                  <input
                    type="text"
                    name="url"
                    placeholder="ex: https://scm.com/org/repo-lang.git"
                    className={styles.inputField}
                    value={url}
                    onChange={e => setUrl(e.target.value)}
                  />
                </label>
                <label className={styles.inputLabel}>
                  Username
                  <input
                    type="text"
                    name="username"
                    placeholder="Enter username"
                    className={styles.inputField}
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                  />
                </label>
                <label className={styles.inputLabel}>
                  Password
                  <input
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    className={styles.inputField}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </label>
              </div>
            )}
            <div className={styles.buttonGroup}>
              <Dialog.Close asChild>
                <DefaultButton buttonType="secondary">Cancel</DefaultButton>
              </Dialog.Close>
              {selectedConnectionType !== 'pixeebotApp' ? (
                <DefaultButton buttonType="primary" onClick={handleConnectClick} state={url ? 'default' : 'disabled'}>
                  Connect
                </DefaultButton>
              ) : (
                <DefaultButton href="https://github.com/apps/pixeebot/installations/new" buttonType="primary">
                  Go to GitHub
                </DefaultButton>
              )}
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
