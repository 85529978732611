import { GitMergeIcon, MarkGithubIcon } from '@primer/octicons-react'
import { ArrowRightIcon } from '@radix-ui/react-icons'
import * as Tabs from '@radix-ui/react-tabs'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AnalysisStatusBadge } from '../components/analysis-status-badge'
import { typography } from '../main.css.ts'
import { Routes } from '../routes.ts'
import { useGetRepositoriesV1 } from '../utils/api-client/user-platform-api-hooks.ts'
import {
  AnalysisStateResponse,
  AnyRepositoryResponse,
  RepositoryType,
  ScanAnalysis,
} from '../utils/api-client/user-platform-api-schemas.ts'
import * as styles from './scans-overview-page.css.ts'

interface ScanProps {
  timestamp: string
  repositoryType: RepositoryType
  branch: string | null
  displayName: string
  analysisId: string
  scanId: string
  state: AnalysisStateResponse
}

export function ScansOverviewPage() {
  const [allRepositories, setAllRepositories] = useState<AnyRepositoryResponse[]>([])
  const [completedScans, setCompletedScans] = useState<Array<ScanProps>>([])
  const [inProgressScans, setInProgressScans] = useState<Array<ScanProps>>([])
  const pageSize = 100

  const { data: firstPageData } = useGetRepositoriesV1({
    pageNumber: 0,
    pageSize,
  })

  const totalPages = firstPageData ? Math.ceil(firstPageData.total / pageSize) : 0
  const additionalQueries = Array.from({ length: totalPages - 1 }, (_, index) =>
    useGetRepositoriesV1({
      pageNumber: index + 1,
      pageSize,
    })
  )

  useEffect(() => {
    if (!firstPageData) return

    const allPages = [firstPageData, ...additionalQueries.map(query => query.data)]
    if (allPages.some(page => !page)) return

    const repositories = allPages.flatMap(page => page!.items)
    setAllRepositories(repositories)
  }, [firstPageData, ...additionalQueries.map(q => q.data)])

  useEffect(() => {
    if (!allRepositories.length) return

    let isMounted = true

    const fetchAnalyses = async () => {
      const analysesPromises = allRepositories.map(repo =>
        fetch(`/api/v1/repositories/${repo.id}/analyses?page_number=0&page_size=2`)
          .then(res => res.json())
          .then((data): { items: ScanAnalysis[] } => data)
          .catch(() => null)
      )

      const analysesResults = await Promise.all(analysesPromises)

      if (isMounted) {
        const completedScans = allRepositories
          .map((repo, index) => {
            const analyses = analysesResults[index]?.items || []
            const analysis = analyses.find(
              a => a?.current_state.state === 'completed_results' || a?.current_state.state === 'completed_no_results'
            )
            if (!analysis?.current_state.timestamp || !analysis.id) return null

            return {
              timestamp: analysis.current_state.timestamp,
              repositoryType: repo.type as RepositoryType,
              branch: analysis.branch || null,
              displayName: repo.name,
              analysisId: analysis.id,
              scanId: analysis._links.scan.href.split('/').pop() || '',
              state: analysis.current_state.state,
            }
          })
          .filter((scan): scan is NonNullable<typeof scan> => scan !== null)
          .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())

        const inProgressScans = allRepositories
          .map((repo, index) => {
            const analyses = analysesResults[index]?.items || []
            const analysis = analyses[0]
            if (!analysis?.current_state.timestamp || !analysis.id) return null
            if (analysis.current_state.state !== 'in_progress' && analysis.current_state.state !== 'queued') return null

            return {
              timestamp: analysis.current_state.timestamp,
              repositoryType: repo.type as RepositoryType,
              branch: analysis.branch || null,
              displayName: repo.name,
              analysisId: analysis.id,
              scanId: analysis._links.scan.href.split('/').pop() || '',
              state: analysis.current_state.state,
            }
          })
          .filter((scan): scan is NonNullable<typeof scan> => scan !== null)
          .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())

        setCompletedScans(completedScans)
        setInProgressScans(inProgressScans)
      }
    }

    fetchAnalyses()

    return () => {
      isMounted = false
    }
  }, [allRepositories])

  const isLoading = !firstPageData || additionalQueries.some(query => query.isLoading)

  return (
    <ScansOverviewPagePure isLoading={isLoading} completedScans={completedScans} inProgressScans={inProgressScans} />
  )
}

export function ScansOverviewPagePure({
  isLoading,
  completedScans,
  inProgressScans,
}: {
  isLoading: boolean
  completedScans: Array<ScanProps>
  inProgressScans: Array<ScanProps>
}) {
  return (
    <div>
      <div className={styles.scansOverviewHeader} role="banner">
        <h4 className={`${typography.h4} ${styles.headerTitle}`}>Scans</h4>
        <p className={`${typography.paragraph} ${styles.headerParagraph}`}>
          Upload, triage and fix your SAST scan findings
        </p>
      </div>

      {isLoading ? (
        <div className={styles.loadingContainer}>Loading scans...</div>
      ) : (
        <Tabs.Root defaultValue="latest" className={styles.tabContainer}>
          <Tabs.List className={styles.tabList}>
            <Tabs.Trigger className={styles.tab} value="latest">
              LATEST &nbsp;<span className={styles.tabItemCount}>{completedScans.length}</span>
            </Tabs.Trigger>
            <Tabs.Trigger className={styles.tab} value="in-progress">
              IN PROGRESS &nbsp;<span className={styles.tabItemCount}>{inProgressScans.length}</span>
            </Tabs.Trigger>
          </Tabs.List>

          <Tabs.Content className={styles.tabContent} value="latest">
            {!isLoading && completedScans.length === 0 ? (
              <p className={styles.noScansMessage}>
                No scans found. Please upload tool results to a connected repository.
              </p>
            ) : (
              completedScans.map(scan => (
                <ScanPure
                  key={`${scan.analysisId}-${scan.scanId}`}
                  timestamp={scan.timestamp}
                  repositoryType={scan.repositoryType}
                  branch={scan.branch}
                  displayName={scan.displayName}
                  analysisId={scan.analysisId}
                  scanId={scan.scanId}
                  state={scan.state}
                />
              ))
            )}
          </Tabs.Content>
          <Tabs.Content className={styles.tabContent} value="in-progress">
            {!isLoading && inProgressScans.length === 0 ? (
              <p className={styles.noScansMessage}>No scans in progress.</p>
            ) : (
              inProgressScans.map(scan => (
                <ScanPure
                  key={`${scan.analysisId}-${scan.scanId}`}
                  timestamp={scan.timestamp}
                  repositoryType={scan.repositoryType}
                  branch={scan.branch}
                  displayName={scan.displayName}
                  analysisId={scan.analysisId}
                  scanId={scan.scanId}
                  state={scan.state}
                />
              ))
            )}
          </Tabs.Content>
        </Tabs.Root>
      )}
    </div>
  )
}

export const ScanPure: FC<ScanProps> = ({ timestamp, repositoryType, branch, displayName, analysisId, state }) => {
  const date = new Date(timestamp)
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
  })
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  })

  const RepositoryIcon =
    repositoryType.toLowerCase() === 'github'
      ? () => <MarkGithubIcon aria-label="GitHub icon" className={styles.scmIcon} />
      : () => <GitMergeIcon aria-label="Git icon" className={styles.scmIcon} />

  return (
    <div className={styles.scansOuterContainer}>
      <div className={styles.scanContainer}>
        <div className={styles.firstCell}>
          <p className={styles.date}>{formattedDate}</p>
          <p className={styles.time}>{formattedTime}</p>
          {branch && (
            <div className={styles.branchContainer}>
              <span className={styles.branchText}>
                <span className={styles.truncatedText} title={branch}>
                  {branch}
                </span>
              </span>
            </div>
          )}
        </div>
        <div className={styles.secondCell}>
          <RepositoryIcon />
          <p className={styles.repositoryName}>{displayName}</p>
        </div>
        <div className={styles.lastCell}>
          {state && state !== 'completed_results' && state !== 'completed_no_results' && (
            <AnalysisStatusBadge variant={state} />
          )}
          <Link to={Routes.AnalysisDetailsPage.createPath(analysisId)} className={styles.analysisLink}>
            View analysis
            <ArrowRightIcon />
          </Link>
        </div>
      </div>
    </div>
  )
}
