export const Routes = {
  LoginPage: { path: '/login', params: [] },
  IntroPage: { path: '/intro', params: [] },
  WelcomePage: { path: '/welcome', params: [] },
  RepositoriesPage: { path: '/repositories', params: [] },
  HomePage: { path: '/', params: [] },
  DeveloperDashboardPage: { path: '/dashboard', params: [] },
  DeveloperDashboardAccountPage: { path: '/dashboard/:accountLogin', params: [':accountLogin'] },
  DeveloperDashboardRepositoryPage: {
    path: '/dashboard/:accountLogin/:repositoryName',
    params: [':accountLogin', ':repositoryName'],
  },
  GenerateSingleFixPage: {
    path: '/fix/:installationId/:repositoryId/:analysisId/:codemodScheme/:codemodName',
    params: [':installationId', ':repositoryId', ':analysisId', ':codemodScheme', ':codemodName'],
  },
  AnalysisDetailsPageDeprecated: {
    path: '/analysis/:analysisId/:scanId',
    createPath: (analysisId: string, scanId: string) => `/analysis/${analysisId}/${scanId}`,
    params: [':analysisId', ':scanId'],
  },
  AnalysisDetailsPage: {
    path: '/analysis/:analysisId',
    createPath: (analysisId: string) => `/analysis/${analysisId}`,
    params: [':analysisId'],
  },
  AnalysisDetailsPageFindingFixDrawer: {
    path: '/analysis/:analysisId/fix/:findingId',
    createPath: (analysisId: string, findingId: string) => `/analysis/${analysisId}/fix/${findingId}`,
    params: [':analysisId', ':findingId'],
  },
  ScansOverviewPage: {
    path: '/scans/',
  },
} as const

export type RouteKey = keyof typeof Routes
